(function ($) {

  "use strict"; 

  /*=================== PRELOADER ===================*/
  $(window).on('load',function() { 
      $(".preloading").fadeOut("slow"); 
  });

  
})(jQuery);