      // datetimepicker     


      //-----JS for Price Range slider-----

$(function() {
  $( "#slider-range" ).slider({
    range: true,
    min: 130,
    max: 500,
    values: [ 130, 250 ],
    slide: function( event, ui ) {
    $( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
    }
  });
  $( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) +
    " - $" + $( "#slider-range" ).slider( "values", 1 ) );
});  
    



// video on click


const Player = document.getElementById('player2');
const PlayBtn = document.getElementById('play');
const stopBtn = document.getElementById('stop');
let times = 0, playY;
const playVideo = PlayBtn.addEventListener( 'click' , () => {
    if(times == 0){
      playY = Player.src += '?autoplay=1';
      times = 1;
    }
});

stopBtn.addEventListener( 'click' , () => {
    playY = playY.slice(0, -11);
    Player.src = playY
    times = 0;
});              